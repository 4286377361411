import React from "react"
import { Link } from "gatsby"

function Nav(props){
    return(
        <nav className={`siteNav ${props.sidebarOn ? "open" : "closed"}`}>
            <div id="sidebarBtn" onClick={() => props.setSidebar()}>
                <span>☰</span>
            </div>
            <div className="topBanner">
                <div>
                    <Link to={'/'}> 
                        <img src="https://i.imgur.com/1x1mvGB.png" alt="© DreamCraft Entertainment"/>
                    </Link>
                </div>
                <div className="links">
                    <Link activeClassName="current" exact to={`/`} 
                    onClick={() => props.setSidebar()}>
                        Home
                    </Link>
                    <Link className={props.active === 'career' ? 'current' : ''} to={`/careers/`}
                    onClick={() => props.setSidebar()}>
                        Careers
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default Nav